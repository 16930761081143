import { CONST } from "./Constants";
import { PublicData } from "_redux/_models/PublicData";
import { AdminMode } from "_redux/_models/AdminMode";

export const initialState = {
  apiReducer: {
    api_jobs: 0,
  },
  app: {
    messages: [],
    overlay: null,
    basketItems: [],
    actualStage: null,
    actualMeeting: null,
    publicData: new PublicData(),
    media: null,
    admin: new AdminMode(),
    entityPicker: false,
    entityPickerVal: null,
    socialUserboardOpened: false,
    messenger: {
      opened: false,
    },
    surveyQuestions: [],
  },
  login: {
    token: CONST._empty,
    user: {
      name: CONST._empty,
      company: CONST._empty,
      email: CONST._empty,
      phone: CONST._empty,
    },
    invitedUser: {},
  },
  messages: {
    stateHash: CONST._empty,
    lastLoadedMessageId: -1,
    call: { lastMessage: null },
    groupMessages: [],
    newMessagesCount: 0,
  },
  users: {
    list: [],
    foundFast: [],
    foundDetailed: [],
    needle: CONST._empty,
  },
  company: {
    availableCompanies: null,
    actualCompany: null,
  },
  workspace: {
    availableWorkspaces: null,
    actualWorkspace: null,
    availableInCompanies: [],
    workSpaceCreated: false,
    requestDone: false,
  },
  documents: {
    documents: [],
    recording: [],
  },
  folders: {
    folders: [],
    docs: [],
    parent: null,
  },
  meeting: {
    availableMeetings: null,
    workspaceMeetings: [],
    actualMeeting: null,
    userNames: {},
    isHost: false,
    set_lobby: false,
    workspacePastMeetings: [],
  },
  survey: {},
  stage: {
    availableStages: [],
    actualStage: null,
  },
  calendar: {
    entry: null,
  },
  ant: {
    users: {},
  },
  loader: {
    isLoading: false,
  },
  modal: {
    data: null,
  },
  websocket: {
    lastMessage: {},
    groups: {},
    listening: false,
    actualGroup: null,
  },
  call: {
    oldCalls: [],
    actualCall: null,
  },
  instructions: {
    stats: [],
  },
  social: {
    companyMessages: [], //admin
    workspaceMessages: [], //admin
    messages: [],
    entity_id: null,
    createdMessage: {},
    actualComments: { msgId: null, comments: [] },
    actualSharing: { entity: null, ts: 0, options: null },
    userMessages: [],
    newMessages: 0,
  },
  inapp:{
    workspaces:[]
  }
};
