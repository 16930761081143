export const INAPP_SET_WORKSPACES_SUCCESS = 'INAPP_SET_WORKSPACES_SUCCESS';
export const INAPP_SET_WORKSPACE_SUCCESS = 'INAPP_SET_WORKSPACE_SUCCESS';
export const INAPP_DOWNLOADER_OPENED = 'INAPP_DOWNLOADER_OPENED';

export const INAPP_OPEN_OFFLINE_PACKAGE = 'INAPP_OPEN_OFFLINE_PACKAGE';

export function setWorkspaces(payload) {
	return {type: INAPP_SET_WORKSPACES_SUCCESS, info:null, payload: payload};
}

export function setWorkspace(payload) {
	return {type: INAPP_SET_WORKSPACE_SUCCESS, info:null, payload: payload};
}
export function setDownloaderOpened(payload) {
	return {type: INAPP_DOWNLOADER_OPENED, info:null, payload: payload};
}

export function openOfflinePackage(payload) {
	return {type: INAPP_OPEN_OFFLINE_PACKAGE, info:null, payload: payload};
}


