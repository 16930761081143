import styled, { css, keyframes } from "styled-components/macro";
import { IsMobile } from "Utils";
import { handleColorType } from "../../styles";

export const OverlayActionsContainer = styled.div.attrs(props=>({className:props.className}))`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: all;
`;

export const OverlayActionsDrawer = styled.div`
  background: ${({ background }) =>
    background ? background : "var(--theme-background)"};
  width: var(--margin-36);
`;

export const MobileOverlayActionsDrawer = styled.div`
  background: var(--bg-dark);
  width: 100vw;
`;

export const DrawerHeader = styled.div`
  margin: 0;
  padding: 5px;
  cursor: pointer;
  color: var(--gray-light);
  width: 100%;
  height: 100%;
  ${({ hasContent }) =>
    hasContent && "border: 1px solid var(--main);" + "border-radius: 3px;"}
`;

export const MobileDrawerHeader = styled.div`
  margin: 0;
  cursor: pointer;
  color: var(--gray-light);
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  ${({ hasContent }) =>
    hasContent && "border: 1px solid var(--main);" + "border-radius: 3px;"}
  .chat-icon {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .badge {
    position: absolute;
    border-radius: 50%;
    width: var(--margin-3);
    height: var(--margin-3);
    background-color: var(--white);
    color: var(--main);
    text-align: center;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 8px;
    right: -6px;
    top: -8px;
  }
`;

export const ChatIconBackground = styled.div`
  background: var(--orange);
  border-radius: 50%;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 320px) {
    height: 50px;
    width: 50px;
    right: 20px;
  }
`;

export const DrawerBody = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: height 1s;
  background: var(--white);

  ${({ active }) =>
    active &&
    css`
      height: calc(90vh - var(--margin-12));
    `}
`;

export const MobileDrawerBody = styled.div`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  background: var(--white);
  width: 100vw;

  ${({ active }) =>
    active &&
    css`
      height: calc(100% - 50px);
      transition: height 0s;
    `}
`;

export const DrawerContent = styled.div`
  height: 100%;
  background: var(--white);
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const DrawerSection = styled.div`
  ${({ maxHeight }) => "max-height: " + maxHeight + ";"}
  ${({ minHeight }) => "min-height: " + minHeight + ";"}
  overflow: auto;
  padding: ${IsMobile() ? "0" : "10px"};
  border-top: 1px solid var(--gray-dark);
`;

export const DrawerActions = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
  height: 5%;
`;

export const OverlayActionsContentItem = styled.div`
  border-bottom: 1px solid var(--gray-middle);
  border-top: 1px solid var(--gray-middle);
  cursor: pointer;
  height: 56px;
  &:hover {
    background-color: var(--gray-light);
    transition: background-color 0.3s;
  }
`;

export const DrawerSectionLabel = styled.div`
  color: var(--main);
  padding: 10px;
  box-shadow: 0px 0px 3.4px rgba(0, 0, 0, 0.065),
    0px 0px 10px rgba(0, 0, 0, 0.13);
`;

export const LeftMenu = styled.div`
  --contact-height: 400px;
  height: ${({ isHalf, parentActive }) =>
    !parentActive
      ? "calc(100% + var(--contact-height))"
      : isHalf
      ? "60%"
      : "100%"};

  width: 340px;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: -340px;
  top: ${({ isHalf, parentActive }) =>
    !parentActive ? "calc(-1 * var(--contact-height))" : isHalf ? "auto" : "0"};
  bottom: ${({ isHalf, parentActive }) =>
    !parentActive ? "0" : isHalf ? "0" : "auto"};
  background-color: var(--bg-light);
  color: var(--black-middle);
  transition: transform 0.5s;
  transform: ${({ isBottom }) => (isBottom ? "scaleY(0)" : "scaleX(0)")};
  transform-origin: ${({ isBottom }) =>
    isBottom ? "bottom center" : "right center"};
  ${({ active }) =>
    active &&
    css`
      transform: ${({ isBottom }) => (isBottom ? "scaleY(1)" : "scaleX(1)")};
    `}
`;

export const ChatOverlay = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  background-color: var(--bg-light);
  color: var(--black-middle);
  transition: ${({ noTransition }) =>
    noTransition ? "none" : "transform 0.45s"};
  transform: scaleX(0);
  transform-origin: right left;
  z-index: 100;
  ${({ active }) =>
    active &&
    css`
      transform: scaleX(1);
    `}
`;

export const LeftMenuHeader = styled.div`
  background-color: var(--bg-white);
  flex: 1;
  max-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const rotate = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

export const ScrollableContainer = styled.div`
  overflow-y: scroll;
  height: calc(100% - 6px);
  ${({ padding }) => padding && "padding: " + padding + ";"}
  ${({ marginTop }) => marginTop && "margin-top: " + marginTop + ";"}
  ${({ marginBottom }) =>
    marginBottom && "margin-bottom: " + marginBottom + ";"}
`;

export const LeftMenuBody = styled.div`
  z-index: 1;
  ${({ indicator }) => "border: 3px solid " + handleColorType(indicator)}}
  flex: 8;
  height: 85%;

  overflow-x: hidden;
  position: relative;
  ${({ loading }) =>
    loading
      ? css`
          border: none;
          &:before {
            content: "";
            position: absolute;
            z-index: -2;
            left: -150%;
            top: -10%;
            width: 400%;
            height: 120%;
            background: linear-gradient(
                -180deg,
                var(--gray-dark) 39%,
                rgba(0, 0, 0, 0) 39%
              ),
              linear-gradient(-180deg, var(--main) 60%, var(--gray-dark) 60%);
            animation: ${rotate} 5s linear infinite;
          }

          &:after {
            content: "";
            position: absolute;
            z-index: -1;
            left: 3px;
            top: 3px;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            background: var(--bg-light);
            border-radius: 2px;
          }
        `
      : ""}
`;

export const LeftMenuActions = styled.div`
  background-color: var(--bg-white);
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 22px;
  flex: 1;
  max-height: 90px;
`;

export const QA_Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
`;

export const QA_Input = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--black-middle);

  & textarea {
    resize: none;
    width: 90%;
  }
`;

export const QA_InputWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
