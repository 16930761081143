import {
	INAPP_SET_WORKSPACES_SUCCESS, INAPP_SET_WORKSPACE_SUCCESS, INAPP_DOWNLOADER_OPENED
} from './../actions/inApp';
import {initialState}  from 'initialState.js';

export function inAppReducer(state = initialState, action) {
	switch(action.type) {
    case INAPP_SET_WORKSPACES_SUCCESS:
			console.log("INAPP_SET_WORKSPACES_SUCCESS");
			console.log(action.payload);

			return {
				...state,
				workspaces: action.payload && Array.isArray(action.payload)?action.payload:[]
			}
		case INAPP_DOWNLOADER_OPENED:
			//console.log("INAPP_SET_DOWNLOADER_OPENED", action.payload);

			return {
				...state,
				opened: action.payload

			}
    case INAPP_SET_WORKSPACE_SUCCESS:
      console.log(action)
      if(!action.payload?.uuid)
        return state;
      let wss = [...state.workspaces];
      let ws = wss.find(w=>w.uuid === action.payload?.uuid);
      if(!ws)
        wss.push(action.payload);
      else
        wss[wss.indexOf(ws)]= action.payload;
			return {
				...state,
				workspaces: wss
			}

		default:
			return state;
	}
}

export const WorkspacesOfflineAvailable = state => state.inapp.workspaces;
export const WorkspacesOfflineOpened = state => {return state.inapp.opened === true};