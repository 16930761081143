import { CONST, DEFAULT_BLOCK, DEFAULT_COMPANY_THEME } from "./Constants";
let lastPortraitWidth = null;
/*eslint-disable */
export function ValidateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function IsIos() {
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) return true;
  return false;
}
export function IsAndroid() {
  if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) return true;
  return false;
}
export function IsFirefox() {
    if (/Fisrefox/i.test(navigator.userAgent)) return true;
    return false;
}

export function IsJitsiScreenSharingAvailable() {
    if (IsFirefox()) return false;

    return true;
}

// #region IsInApp
let _isInApp = null;
const simulateOffline = false;
export function DetermineIsInApp() {
  if(window.navigator.userAgent.indexOf("inApp") > -1)
    _isInApp = true;
  else
    _isInApp = false;
}

export function IsInApp() {
  if(simulateOffline)
    return true;

  if(_isInApp == null)
    DetermineIsInApp();
  return _isInApp;
}
// #endregion IsInApp

// #region IsOffline
let _isOffline = false;
export function DetermineIsOffline(val){
  _isOffline = window.navigator.userAgent.indexOf("offline") > -1;
}
export function IsOffline() {
  if(simulateOffline)
    return true;
  return _isOffline;
}
// #endregion IsOffline

// #region IsMobile

const mobileRegex1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
const mobileRegex2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;



export function IsMobile() {

  if(!IsPortrait())
    return false;
  if(IsInApp())
    return true;
  // Solution found https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
  let check = false;
  (function (a) {
    if (mobileRegex1.test(a) || mobileRegex2.test(a.substr(0, 4)))
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}
// #endregion IsMobile

/*eslint-enable */
export function IsPortrait() {
  if (window.innerWidth < window.innerHeight)
    lastPortraitWidth = window.innerWidth;

  return (
    window.innerWidth < window.innerHeight ||
    lastPortraitWidth === window.innerWidth
  );
}

export function ConvertBBToHTMl(str) {
  str = str.replace("[i]", "<i>");
  str = str.replace("[/i]", "</i>");
  str = str.replace("[b]", "<b>");
  str = str.replace("[/b]", "</b>");
  str = str.replace("#b#", "<b>");
  str = str.replace("#/b#", "</b>");
  str = str.replace("<b></b><br/>", "");
  str = str.replace("<b>  </b><br/>", "");
  return str;
}

export function ConvertLineBreaks(str) {
  if (str == null) return "";

  // eslint-disable-next-line
  if (str == parseFloat(str)) str = str + "";
  str = str.split("\r\n").join("<br/>");
  str = str.split("\n").join("<br/>");
  str = str.split("\r").join("<br/>");

  return str;
}

export function ConvertNumber(str) {
  if (str == null) return "";
  // eslint-disable-next-line
  if (str == parseFloat(str)) str = str + "";
  str = str.replace("[i]", "<i>");
  str = str.replace("[/i]", "</i>");
  var res = str.replace(/<number>(.*?)<\/number>/g, function (x) {
    x = x.replace("<number>", "<span class='nr'>");
    x = x.replace("</number>", "</span>");
    x = x.replace(/\B(?=(\d{3})+(?!\d))/g, " "); // &thinsp;
    return x;
  });
  return res;
}

export function FormatSecondsToTime(seconds, rounded = false) {
  if (seconds == null) return "";
  seconds = parseInt(seconds + "");
  let _hours = parseInt(seconds / 3600);
  let _minutes = parseInt(seconds / 60);
  let _seconds = seconds % 60;

  if (rounded) {
    if (_hours > 0) {
      return Math.round(_hours + _minutes / 60) + " h";
    }
    if (_minutes > 0) {
      return Math.round(_minutes + _seconds / 60) + " min";
    }
    return _seconds + " sek";
  }

  if (_hours > 0)
    return pad(_hours, 2) + ":" + pad(_minutes, 2) + ":" + pad(_seconds, 2);

  return pad(_minutes, 2) + ":" + pad(_seconds, 2); //"00:00";
}
export function ConvertMySqlDateForDisplay(dateString, showTime = true) {
  var dateTime = dateString.split(" ");
  if (dateTime.length <= 1) dateTime = dateString.split("T");
  var date = dateTime[0].split("-");
  var time = dateTime[1].split(":");

  var utc = Date.UTC(
    date[0],
    date[1] - 1,
    date[2],
    time[0],
    time[1],
    time[2].indexOf("Z") > -1 ? time[2].split("Z").join("") : time[2]
  );

  let _utcdate = new Date(utc);

  if (showTime)
    return (
      _utcdate.getDate() +
      "." +
      pad(_utcdate.getMonth() + 1) +
      "." +
      pad(_utcdate.getFullYear()) +
      " - " +
      pad(_utcdate.getHours()) +
      ":" +
      pad(_utcdate.getMinutes()) +
      "h"
    );

  return (
    _utcdate.getDate() +
    "." +
    pad(_utcdate.getMonth() + 1) +
    "." +
    pad(_utcdate.getFullYear())
  );
}

export function pad(padString, length) {
  if (length == null) length = 2;
  var str = padString + "";
  while (str.length < length) str = "0" + str;
  return str;
}

export function GetArgs() {
  let parts = document.location.hash.split("#/");
  if (parts.length <= 1) return "";
  if (parts.length > 1) {
    let _parts = parts[1].split("/");
    return _parts;
  }
}

export function GetScope() {
  return GetId();
}

export function GetId(pos = 0) {
  let args = GetArgs();
  if (args.length > pos) return decodeURI(args[pos]);

  return CONST._empty;
}

export function CreateUUID() {
  /* eslint-disable */
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
  /* eslint-enable */
}

export function CreateGroupIdFromUserIds(uuid1, uuid2) {
  if (uuid1 == null) return uuid2;
  if (uuid2 == null) return uuid1;
  if (uuid1.localeCompare(uuid2) < 0) return uuid1 + "#" + uuid2;

  return uuid2 + "#" + uuid1;
}

export function IsClickOutside(event, notelem) {
  //console.log(notelem.getBoundingClientRect())
  //console.log(event.clientX, event.clientY)
  let rect = notelem.getBoundingClientRect();
  if (event.clientX < rect.left || event.clientX > rect.right) return true;
  if (event.clientY < rect.top || event.clientY > rect.bottom) return true;

  return false;
}

//#region hashstring
function djb2(str) {
  var hash = 5381;
  for (var i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
}

export function ToShort(string){
  if(!string)
    return "";
  if(string.indexOf(" ")=== -1 && string.length<=2){
    return string;
  }

  if(string.split(" ").length==1){
    if(string.length<=2)
      return string;
    else
      return string.substr(0,2);
  }

  string = string.split("  ").join(" ");
  if(string.split(" ").length>1){
    return string.split(" ")[0][0]+string.split(" ")[1][0];
  }

  return "?";
}
export function HashStringToColor(str) {
  if (str == null) str = "anonymous";
  var hash = djb2(str);
  var r = (hash & 0xff0000) >> 16;
  var g = (hash & 0x00ff00) >> 8;
  var b = hash & 0x0000ff;
  return (
    "#" +
    ("0" + r.toString(16)).substr(-2) +
    ("0" + g.toString(16)).substr(-2) +
    ("0" + b.toString(16)).substr(-2)
  );
}

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
//#endregion hashstring

export function isLandscape() {
  return window.innerWidth > window.innerHeight;
}

export const convertCompanyTheme = (theme) => {
  if (theme) {
    return { ...DEFAULT_COMPANY_THEME, ...JSON.parse(theme) };
  }
  return DEFAULT_COMPANY_THEME;
};

export const getEventId = (pathName) => {
  return pathName.split("/login/register/event/")?.[1];
};

export const getDetailsData = (data) => {
  let details = { ...DEFAULT_BLOCK.details };
  if (data.details && typeof data.details === "string") {
    details = { ...details, ...JSON.parse(data.details) };
  }
  return details;
};

export const setBgColor = (block) => {
  if (block?.details) {
    const details = JSON.parse(block.details);
    return details.formColor || "rgba(51, 51, 51, 0.9)";
  }
  return "rgba(51, 51, 51, 0.9)";
};

export const getTopSpace = (block) => {
  if (block?.details) {
    const details = JSON.parse(block.details);
    return details.topSpace && parseInt(details.topSpace) >= 0
      ? details.topSpace + "px"
      : "32px";
  }
  return "32px";
};

export const getBottomSpace = (block) => {
  if (block?.details) {
    const details = JSON.parse(block.details);
    return details.bottomSpace && parseInt(details.bottomSpace) >= 0
      ? details.bottomSpace + "px"
      : "32px";
  }
  return "32px";
};

export const getImageType = (block) => {
  if (block.details) {
    const details = JSON.parse(block.details);
    return details.imageType || "normal";
  }
  return "normal";
};

export const getBackgroundImage = (block) => {
  if (block?.details) {
    const details = JSON.parse(block.details);
    return details.imageType === "background" && block.image ? block.image : "";
  }
  return "";
};

export const FileType={
  public_data:'public_data'

}
export const getFilePath = (fileType, url, ws_id)=>{

  if(!IsOffline())
    return url;
  if(!url)
    return;

  let apiDomain = process.env.REACT_APP_INAPP_API_DOMAIN;
  let apiUrl = process.env.REACT_APP_INAPP_API_URL+ws_id+"/";

  if(fileType === FileType.public_data)
  {
    return apiUrl+"/files/public_data"+url.split("public_data")[1];
  }else{

    if(url.split("files/pageitems/").length>1)
      return url.split("http://127.0.0.1:8000/").join(apiUrl);
  }
}

function sendNotification(title, message, user, notAction) {
  // if (document.visibilityState !== "visible") {
  if (typeof Notification === "undefined") return;
  const notification = new Notification(`${title} ${user}`, {
    body: message,
  });

  notification.onclick = () => {
    if(!notAction){
      window.open(window.location.href);
    }
    notification.close()
  };
  // }
}


export function IsEmbedded(){
  if(document.location?.hash?.indexOf(CONST.pathes.showroomEmbedded._)>-1)
    return true;
}

export default function checkPageStatus(title, message, user, notAction) {
  if (typeof Notification === "undefined") {
      console.log("Browser does not support notifications");
      return;
  }
  if (Notification.permission === "granted") {
    sendNotification(title, message, user, notAction);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission((permission) => {
      if (permission === "granted") {
        sendNotification(title, message, user, notAction);
      }
    });
  }
}




